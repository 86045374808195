@import "../css/variables";

$modalOverlayBackground: var(--overlay-color, rgba(black, 0.75));
$modalSepColor: var(--separator-color, $gray-color-light);

.sea-modal {
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: $layout-spacing;
  position: fixed;
  right: 0;
  top: $safe-top;

  &:target,
  &.active {
    display: flex;
    opacity: 1;
    z-index: $z-index-overlay;

    .sea-modal-overlay {
      background: $modalOverlayBackground;
      bottom: 0;
      cursor: default;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .sea-modal-container {
      animation: slide-down 0.2s ease 1;
      z-index: $z-index-modal;
    }
  }

  //&.modal-sm {
  //  .modal-container {
  //    max-width: $control-width-sm;
  //    padding: 0 $unit-2;
  //  }
  //}
  //
  //&.modal-lg {
  //  .modal-overlay {
  //    background: $bg-color-light;
  //  }
  //
  //  .modal-container {
  //    box-shadow: none;
  //    max-width: $control-width-lg;
  //  }
  //}
}

.sea-modal-container {
  // @include shadow-variant(.2rem);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background: $bg-color-light;
  border: $px-1 solid $modalSepColor;
  border-radius: $px-8; // $border-radius;
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  max-width: $control-width-md;
  padding: 0;
  width: min-content;
  min-width: 50vw; // px(400);
  // width: 100%;

  &.-fullheight {
    max-height: 100vh;
  }

  .sea-modal-header {
    color: $dark-color;
    padding: $px-16;
    font-weight: $font-weight-medium;
    border-bottom: $px-1 solid $modalSepColor;
  }

  .sea-modal-body {
    color: $dark-color;
    overflow-y: auto;
    padding: $px-16;
    position: relative;
  }

  .sea-modal-footer {
    color: $dark-color;
    border-top: $px-1 solid $modalSepColor;
    padding: $px-16;
    text-align: right;
    white-space: nowrap;
  }
}

@include mediaMobile {
  .sea-modal-container {
    max-height: inherit;
    min-width: inherit;
    max-width: inherit;
    width: 100%;
    height: 100%;
    margin: 1rem;
  }
}

@include notMediaMobile {
  .sea-panel.sea-modal {
    position: static;
    padding: 0;
    margin: 0;
    color: black;
    background: white;
    width: clamp(25rem, 25%, 50%);

    .sea-modal-container {
      position: static;
      max-height: inherit;
      min-width: inherit;
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: 0;
      box-shadow: none;
      background: $bg-color-light;
      border: none;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .sea-modal-overlay {
      display: none;
    }
  }
}
